// export function camel (str) {
//   const camel = (str || '').replace(/-([^-])/g, g => g[1].toUpperCase());

//   return capitalize(camel);
// }

// export function camelActual (str) {
//   return (str || '').replace(/-(\w)/g, (_, c) => (c ? c.toUpperCase() : ''));
// }

// export function kebab (str) {
//   return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
// }

// export function capitalize (str) {
//   str = str || '';

//   return `${str.substr(0, 1).toUpperCase()}${str.slice(1)}`;
// }

// export function findProduct (store, id) {
//   return store.state.store.products.find(p => p.id === id);
// }

// export function isOnSale (variants) {
//   return variants.some(variant => {
//     return parseFloat(variant.price) < parseFloat(variant.compareAtPrice);
//   });
// }

// export function randomNumber (min, max) {
//   return Math.floor(Math.random() * max) + min;
// }

// export function randomString (length = 5) {
//   let text = '';
//   const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

//   for (let i = 0; i < length; i++) {
//     text += possible.charAt(Math.floor(Math.random() * possible.length));
//   }

//   return text;
// }

var CryptoJS = require('crypto-js')

export function randomPinNumber() {
  var pin = '';

  var random_number_1 = Math.floor(Math.random()* 9) + 1
  var random_number_2 = Math.floor(Math.random()* 9) + 1
  var random_number_3 = Math.floor(Math.random()* 9) + 1
  var random_number_4 = Math.floor(Math.random()* 9) + 1

  pin = random_number_1.toString() + "" + random_number_2.toString() + "" + random_number_3.toString() + "" + random_number_4.toString()

  return pin


}

const randomElement = (arr = []) => {
    return arr[Math.floor(Math.random() * arr.length)];
  };
  
  const kebab =  (str) => {
    return (str || '').replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  };
  
  const toggleFullScreen = () => {
    let doc = window.document;
    let docEl = doc.documentElement;
  
    let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    let cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  
    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  };

  export function getCurrentDate() {
      let dt = new Date()
      let currentDate = dt.getFullYear() + '-' + pad(parseInt(dt.getUTCMonth()) + 1) + '-' + pad(dt.getDate())

      return currentDate
  }

  export function getCurrentTime() {
      let dt = new Date()
      return dt.getHours() + ":" + String(dt.getMinutes()).padStart(2, "0")
  }

  export function formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
  }

  function pad(n){return n<10 ? '0'+n : n}

  export function getUserSettingsFromStorage(key) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null
    if(userSettings !== null) {
      var bytes  = CryptoJS.AES.decrypt(userSettings, process.env.VUE_APP_CRYPTO_JS_KEY);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      userSettings = decryptedData
    }
    
    window.console.log(userSettings)

    return userSettings
  }

  export function storeUserSettingsToLocalStorage(key, data) {
    var userSettings = JSON.parse(window.localStorage.getItem(key)) || null

    if(userSettings == null) {
      userSettings = window.localStorage.setItem(key, JSON.stringify(data))
    }
    
    for(var property in data) {
      userSettings[property] = data[property]
    }

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(userSettings), process.env.VUE_APP_CRYPTO_JS_KEY).toString();
    //window.localStorage.setItem(key, JSON.stringify(userSettings))
    window.localStorage.setItem(key, JSON.stringify(ciphertext))
    window.console.log(userSettings)
    window.console.log(ciphertext)

    return userSettings
  }

  export function getUserApplicationRole() {
    var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
    var userRole = null;
    
    if(user == null || user.application_role == null || user.application_role == undefined) {
      return null
    }

    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    user = decryptedData
    userRole = user.application_role

    //window.console.log("Utilities@getUserApplicationRole(): " + userRole)
    //window.console.log(user.type)

    return userRole

  }

  export function getUserApplicationPermissions() {
    var user = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_KEY)) || null
    var userPermissions = [null];
    
    if(user == null || user.application_permissions == null || user.application_permissions == undefined) {
      return []
    }

    var bytes  = CryptoJS.AES.decrypt(user, process.env.VUE_APP_CRYPTO_JS_KEY);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    user = decryptedData

    userPermissions = user.application_permissions

    //window.console.log("Utilities@getUserApplicationPermissions(): ")
    //window.console.log(user.type)

    /*userPermissions.forEach(item => {
      window.console.log("Permission: " + item);
    })*/

    return userPermissions
  }

  export function checkPermissions(requestedPermissions, permissions) {

    //window.console.log("xxx")
    //window.console.log(requestedPermissions)
    if(requestedPermissions.length == 0) {
      return true;
    }

    var permissionFound = false
    requestedPermissions.forEach(rp => {
      permissions.forEach(userPermission => {
        if(rp === userPermission) {
          permissionFound = true;
        }
      })
    })

    return permissionFound;
  }

  export function create_UUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });

    return uuid;
  }

  export function isMobileDevice(obj) {    
    if(obj.$vuetify.breakpoint.mdAndDown) {
      return true;
    }

    return false;
  }

  export function getMonth2Digits(monthValue) {
      // 👇️ Add 1, because getMonth is 0-11
      const month = monthValue + 1;
    
      if (month < 10) {
        return '0' + month;
      }
    
      return month;
  }

  export function getDay2Digits(dayValue) {
    const day = dayValue;
  
    if (day < 10) {
      return '0' + day;
    }
  
    return day;
  }

  export function defaultAppSettings() {
    return {
      dt_events: {
          items_per_page: 10,
          columns: [
            "id",
            'user_name',
            "device_name",
            "card_id",
            "card_number",
            //"ticket_number",
            "event_name",
            "event_timestamp_formatted",
            "action"
          ],
      },

      dt_users: {
          items_per_page: 10,
          columns: [
            "id",
            "name",
            //"address",
            "user_type.name",
            "card_id",
            "card_number",
            //"registration_numbers",
            "restriction_start_date_formatted",
            "restriction_end_date_formatted",
            "action"
          ]
      },

      dt_parking_barriers_archive: {
          items_per_page: 10,
          columns: [
            "id",
            "user_name",
            "device_name",
            "event_name",
            "event_timestamp_formatted"
          ]
      },

      dt_exports: {
          items_per_page: 10,
          columns: [
            "id",
            "name",
            "export_status_name",
            "file_type_for_humans",
            "file_size_readable",
            "execution_time_for_humans",
            "group",
            "user",
            "created_at_formatted",
            "action"
          ]
      },

      events: {
          filter_results_open: true
      },

      users: {
          filter_results_open: true,
          map_open: true
      },

      parking_barriers_archive: {
          filter_results_open: true
      },

        turnstile_timeline: {
          filter_results_open: true
      }
    }
  }

  export function getAppSettingsFromLocalStorage(obj) {
    let appSettings = JSON.parse(window.localStorage.getItem(process.env.VUE_APP_SETTINGS_KEY)) || null
    //window.console.log("=== === === === === === === === === === === === === === === === === === === === === ===")
    //window.console.log(appSettings)

    if(appSettings) {
      let setting = appSettings[obj.setting]
      //window.console.log("setting:")
      //window.console.log(setting)
      if(setting && setting !== undefined) {
        //window.console.log("value: ", setting[obj.key])
        return setting[obj.key]
      }
    }

    return null
  }

  export function parseStatusStr(status, gateIndex) {
    
    /*
    Statusi: 000000000000000
    1 - Najavna zanka Z1
    2 - Najavna zanka Z2
    3 - Najavna zanka Z3
    4 - Varovalna zanka Z1
    5 - Varovalna zanka Z2
    6 - Varovalna zanka Z3
    7 - Pozicija Z1
    8 - Pozicija Z2
    9 - Pozicija Z3
    10 - Stalni dvig Z1
    11 - Stalni dvig Z2
    12 - Stalni dvig Z3
    13 - Semafor Način
    14 - Semaform Stanje
    15 - Izpad napajanja

    Zanke
    0 - prosta
    1 - pokrita

    Zapornice
    0 - spuščena
    1 - dvignjena
    */

    let data = {
      gate_position: null, // 0 -> spuščena, 1 -> dvignjenja
      gate_constant_rise: null // 0 -> spust, 1 -> stalni dvig
    }

    if(status.length >= 12) {
      let statusArray = [...status];

      if(gateIndex == 1) {
        data.gate_position = statusArray[6];
        data.gate_constant_rise = statusArray[9];
      }

      if(gateIndex == 2) {
        data.gate_position = statusArray[7];
        data.gate_constant_rise = statusArray[10];
      }

      if(gateIndex == 3) {
        data.gate_position = statusArray[8];
        data.gate_constant_rise = statusArray[11];
      }
    }

    return data
  }

  export function parseStatusStrV2(status, gateIndex) {
    
    /*
    Statusi: 00111100110

    1  VHODNA ZANKA				(0=OFF	1=ON)
    2	 IZHODNA ZANKA				(0=OFF	1=ON)
    3  POZICIJA VHODNE ZAPORNICE	(0=DVIGNJENA	1=SPUŠČENA)
    4	 POZICIJA IZHODNE ZAPORNICE	(0=DVIGNJENA	1=SPUŠČENA)
    5	 VHODNA VAROVALNA ZANKA		(0=ON	1=OFF)
    6	 IZHODNA VAROVALNA ZANKA		(0=ON	1=OFF)
    7	 VHOD STALNI DVIG			(0=NI AKTIVEN	1=AKTIVEN)
    8	 IZHOD STALNI DVIG			(0=NI AKTIVEN	1=AKTIVEN)
    9	 NAČIN SEMAFORJA				(0=ROČNO		1=AVTOMATSKI)
    10 STANJE SEMAFORJA			(0= IZKLJUČEN	1=ZELENA	2=RDEČA)
    11 STANJE NAPAJANJA			(0=OK	1=IZPAD)
    */

    let data = {
      gate_position: null, // 0 -> spuščena, 1 -> dvignjenja
      gate_constant_rise: null // 0 -> spust, 1 -> stalni dvig
    }

    if(status.length >= 10) {
      let statusArray = [...status];

      if(gateIndex == 1) {
        data.gate_position = (statusArray[2] == 0 ) ? 1 : 0;
        data.gate_constant_rise = statusArray[6];
      }

      if(gateIndex == 2) {
        data.gate_position = (statusArray[3] == 0) ? 1 : 0;
        data.gate_constant_rise = statusArray[7];
      }
    }

    return data
  }

  export function parseStatusStrV3(status, gateIndex) {
    
    /*
    STATUSI STRAŽA
    VHOD
    
    V
    H
    1 	VHODNA ZANKA				(0=OFF	1=ON)
    2	
    3 	POZICIJA VHODNE ZAPORNICE	(0=DVIGNJENA	1=SPUŠČENA)
    4	
    5	VHODNA VAROVALNA ZANKA		(0=ON	1=OFF)
    6	
    7	VHOD STALNI DVIG			(0=NI AKTIVEN	1=AKTIVEN)
    8	

    IZHOD

    I
    Z
    1 	
    2	IZHODNA ZANKA				(0=OFF	1=ON)
    3 	
    4	POZICIJA IZHODNE ZAPORNICE	(0=DVIGNJENA	1=SPUŠČENA)
    5	
    6	IZHODNA VAROVALNA ZANKA		(0=ON	1=OFF)
    7	
    8	IZHOD STALNI DVIG			(0=NI AKTIVEN	1=AKTIVEN)
    */

    let data = {
      gate_position: null, // 0 -> spuščena, 1 -> dvignjenja
      gate_constant_rise: null // 0 -> spust, 1 -> stalni dvig
    }

    if(status.length >= 10) {
      let statusArray = [...status];

      if(gateIndex == 1) {
        data.gate_position = (statusArray[4] == 0 ) ? 1 : 0;
        data.gate_constant_rise = statusArray[8];
      }

      if(gateIndex == 2) {
        data.gate_position = (statusArray[16] == 0) ? 1 : 0;
        data.gate_constant_rise = statusArray[20];
      }
    }

    return data
  }
  
  export default {
    randomElement,
    toggleFullScreen,
    kebab
  };